import styled from "styled-components"
import { StyledSliderItem } from "./SliderItemStyle"

export const StyledSliderWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: white;
  padding: ${props => (props.zoomFactor / props.visibleSlides) * 0.7 + "%"} 0;
  .button-wrapper {
    position: absolute;
    width: 55px;
    height: 100%;
    top: 0;
    padding: ${props => props.zoomFactor / 7 + "%"} 0;
    box-sizing: border-box;
  }
  h2 {
    font-size: var(--mainheading);
    margin-top: 1.5rem;
  }
  .button {
    display: block;
    background: rgb(0, 0, 0, 0.01);
    border: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: var(--accent);
    font-size: 3rem;
    cursor: pointer;
    outline: none;
    transition: all 0.7s;
    user-select: none;
    :hover {
      opacity: 0.5;
    }
  }
  .back {
    left: 0;
    border-radius: 0 1.5vw 1.5vw 0;
  }
  .forward {
    right: 0;
    border-radius: 1.5vw 0 0 1.5vw;
  }
`

export const StyledSlider = styled.div`
  display: flex;
  //padding: 0 55px;
  transition: transform ${props => props.pageTransition}ms ease;
  :hover ${StyledSliderItem} {
    transform: translateX(${props => props.transformValue});
  }
`
