import styled from "styled-components"

export const StoryWrapper = styled.div`
  .specifications {
    p {
      margin-bottom: 0;
    }
  }
  p,
  h4,
  h1 {
    line-height: 1.5;
  }
  .dowload_fiche {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    color: transparent;
    & :hover {
      opacity: 0.6;
    }
  }

  .MuiSvgIcon-root {
    fill: #424242;
  }
  .row {
    padding: 40px 0 0 0;
    display: flex;
    justify-content: space-between;
    & h4 {
      margin-bottom: 20px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    border-bottom: 2px solid #e1e1e1 !important;
    padding-bottom: 20px;
  }

  max-width: 1080px;
  margin: 0 auto;
  padding: 0 40px;
  h3 {
    text-align: center;
    border-bottom: 2px solid #e1e1e1 !important;
    padding-bottom: 20px;
  }
  p {
    width: 290px;
    margin-bottom: 20px;
    text-align: justify;
  }
  @media only screen and (max-width: 980px) {
    h3 {
      text-align: start;
    }
    p {
      width: 30vw;
    }
  }
`
export const HeadingWrapper = styled.div`
  p,
  h4,
  h1 {
    line-height: 1.5;
  }
  h1 {
    color: #424242;
    margin: 2rem 0;
  }
  h4 {
    color: #424242;
  }

  .heading {
    //width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 500px;
    padding: 80px;
  }

  .gatsby-image-wrapper {
    margin-right: 5rem;
  }

  p {
    margin-top: auto;
    text-align: justify;
  }
  @media only screen and (min-width: 545px) and (max-width: 980px) {
    padding: 0;
    .heading {
      flex-direction: column;
      padding: 0;
    }
    .text-wrapper {
      max-width: 100%;
      padding: 0 40;
    }
    .gatsby-image-wrapper {
      marign: 0 auto;
      margin-right: 0;
    }
  }
`
export const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px;
  .gatsby-image-wrapper {
    height: 400px;
    object-fit: contain;
    align-self: center;
  }

  @media only screen and (max-width: 980px) {
    padding: 20px;
  }
  @media only screen and (max-width: 722px) {
    .heading {
      flex-direction: column;
      padding: 0;
    }
    .gatsby-image-wrapper {
      width: 74px;
      height: 260px;
      margin: 0 auto;
    }
    .adjust {
      margin-bottom: 0;
    }
    .pd2 {
      margin-top: 1rem;
    }
    .text-wrapper {
      padding: 0 40px;
    }
    .row {
      padding: 20px 0 0 0;
      flex-direction: column;
      & p {
        width: 100% !important;
      }
    }
  }
`
