import React from "react"

import Slider from "../Slider"

import Placeholder from "../../assets/images/newpl.jpg"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SliderProps = {
  zoomFactor: 30, // How much the image should zoom on hover in percent
  slideMargin: 10, // Margin on each side of slides
  maxVisibleSlides: 7,
  pageTransition: 500, // Transition when flipping pages
}

export default function Caroussel({ CarousselData, Heading }) {
  return (
    <section id="wines">
      <h2
        style={{ fontSize: 30, textAlign: "center", paddingTop: 40 }}
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {Heading}
      </h2>

      {/* <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
        <WineCard Wine={activeWine} />
      </Dialog> */}

      <Slider {...SliderProps}>
        {CarousselData.nodes.map((Wine, index) => (
          <div
            style={{ textAlign: "center", marginBottom: "1rem" }}
            key={Wine.slug}
          >
            <Link to={`/wine/${Wine.slug}/`}>
              {Wine.MainImage ? (
                <GatsbyImage
                  image={
                    Wine.MainImage.localFile.childImageSharp.gatsbyImageData
                  }
                  style={{ height: "200px", width: "50px" }}
                />
              ) : (
                <img
                  src={Placeholder}
                  alt="need image"
                  className="imagewrapper"
                  style={{ height: "200px" }}
                />
              )}
              <h2>{Wine.title}</h2>
            </Link>
          </div>
        ))}
      </Slider>
    </section>
  )
}
