import { useStaticQuery, graphql } from "gatsby"

export const GetRandom = () => {
  const { strapiRandomImages } = useStaticQuery(
    graphql`
      query Randomimages {
        strapiRandomImages {
          RandomImages {
            Image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1800, quality: 60)
                }
              }
            }
            Inspiration
          }
        }
      }
    `
  )
  return strapiRandomImages
}
