import React from "react"
import Layout from "../src/components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper, HeadingWrapper, StoryWrapper } from "./wineTemplate.styles"
import ImageCover from "../src/components/ImageCover"
import Caroussel from "../src/components/WineCaroussel/Caroussel"
import { GetRandom } from "../src/hooks/use-random-images"
import Seo from "../src/components/seo"
import Placeholder from "../src/assets/images/placeholder-image.png"
import GetAppIcon from "@material-ui/icons/GetApp"

const WinePage = ({ pageContext, data }) => {
  //const items = data.images.RandomImages

  const { RandomImages } = GetRandom()

  const image = RandomImages[Math.floor(Math.random() * RandomImages.length)]

  const { wine } = pageContext
  const allergens = wine.specifications.filter(
    label => label.label.includes("Allergens") && label.value !== ""
  )

  const varietals = wine.grapesvarieties?.variety.map(
    e => e.name + ": " + e.amount
  )

  const theWine = wine.presentation[0].value.replace(/(?:&nbsp;|<br \/>)/g, "")
  const Vinification = wine.presentation[7].value.replace(
    /(?:&nbsp;|<br \/>)/g,
    ""
  )

  return (
    <Layout relative={false}>
      <Seo title={wine.title} description={wine.Description} />
      <ImageCover
        image={image.Image.localFile.childImageSharp.gatsbyImageData}
        heading={wine.title}
        section={"#showcase"}
        third
        truedat={true}
      />
      <Wrapper id="showcase">
        <HeadingWrapper>
          <div className="heading">
            {wine.MainImage ? (
              <GatsbyImage
                image={wine.MainImage.localFile.childImageSharp.gatsbyImageData}
              />
            ) : (
              <img
                src={Placeholder}
                alt="Placeholder temporary"
                className="gatsby-image-wrapper"
                style={{ objectFit: "cover", width: "200px" }}
              />
            )}

            <div className="text-wrapper">
              <h1>{wine.title}</h1>
              <p>{wine.Description}</p>
            </div>
          </div>
          <StoryWrapper>
            <div className="row">
              <div>
                <h4>{wine.presentation[0].label}</h4>
                <p>{theWine}</p>
              </div>
              <div>
                <h4>{wine.presentation[7].label}</h4>
                <p className="adjust">{Vinification}</p>
              </div>
            </div>
            <div className="row">
              <div style={{ marginBottom: "1rem" }}>
                <h4>Varietals</h4>
                {varietals ? (
                  varietals.map((e, index) => (
                    <div key={index}>
                      <p style={{ marginBottom: "0.2rem" }}>{e}</p>
                    </div>
                  ))
                ) : (
                  <p>no data</p>
                )}
              </div>
              <div style={{ mindWidth: "290px" }} className="specifications">
                <h4 className="pd2">Specifications</h4>
                <p>
                  {wine.specifications[0].label}: {wine.specifications[0].value}
                  % vol.
                </p>
                <p>
                  {wine.specifications[4].label}: {wine.specifications[4].value}
                </p>
                <p>Allergenes: {allergens.map(e => e.value + ".\n")}</p>
              </div>
            </div>

            <div
              style={{
                marginTop: "5rem",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                className="dowload_fiche"
                href={`https://vincod.com/${pageContext.vincod}/get/print`}
                target="_blank"
                rel="noreferrer"
              >
                <GetAppIcon style={{ fontSize: "50px", zIndex: "100" }} />

                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#424242",
                  }}
                >
                  Download Details
                </p>
              </a>
            </div>
          </StoryWrapper>
        </HeadingWrapper>
        {data.sugg.nodes.length > 0 && (
          <Caroussel CarousselData={data.sugg} Heading={"Related"} />
        )}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query ($wineryid: String!, $vincod: String!) {
    sugg: allStrapiWines(
      filter: { wineryid: { eq: $wineryid }, vincod: { ne: $vincod } }
    ) {
      nodes {
        slug
        title
        vincod
        Description
        MainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 60)
            }
          }
        }
      }
    }
  }
`

export default WinePage
